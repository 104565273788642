import {computed, ref} from 'vue';
import Api from '../features/Api';
import {FullTaskSchema, IFullTask} from '../models/FullTask';
import useTasks from '../composeables/useTasks';
import useReviewData from '../composeables/useReviewData';
import {ReviewDataSchema} from '../models/ReviewData';
import z from 'zod';

const activeTaskRef = ref<Readonly<IFullTask>>();
const errorsRef = ref<string[]>([]);
const isSubmitPromptEnabledRef = ref(false);
let taskPromise: Promise<void>|undefined;
let autosaveTimeout: number|undefined = undefined;

// Success Response (Might not contain 'errors' array)
const TaskSuccessResponseSchema = z.object({
    success: z.literal(true),
    errors: z.tuple([]).optional().default([]),
    reviewData: ReviewDataSchema.nullish(),
    task: z.record(z.unknown())
});

// Failure Response (Might not contain 'task' object
const TaskFailureResponseSchema = z.object({
    success: z.literal(false),
    errors: z.array(z.string()),
    reviewData: ReviewDataSchema.nullish(),
    task: z.record(z.unknown()).optional()
});

const TaskResponseSchema = z.union([TaskSuccessResponseSchema, TaskFailureResponseSchema]);

const TaskProgressSchema = z.object({
    promptSubmit: z.boolean()
});

export default () => {
    function setActiveTask(task: IFullTask) {
        const _task = Object.freeze(task);
        activeTaskRef.value = _task;
        useTasks().setTask(_task);
    }

    function setErrors(errorData: string[]) {
        errorsRef.value = errorData;
    }

    return {
        isLoading: computed(() => Boolean(taskPromise)),
        activeTask: computed(() => activeTaskRef.value),
        isSubmitPromptEnabled: computed(() => isSubmitPromptEnabledRef.value),
        errors: computed(() => errorsRef.value ?? []),
        reviewId: computed(() => {
            if (!activeTaskRef.value?.peerReviewId) {
                throw 'No active task with a Peer Review ID is currently set!'
            }

            return activeTaskRef.value.peerReviewId;
        }),

        setSubmitTaskPrompt(isEnabled: boolean) {
            window.onbeforeunload = isEnabled ? () => true : null;
            isSubmitPromptEnabledRef.value = isEnabled;
        },

        async openTask(taskId: number, clearCurrentTask = true) {
            if (!taskPromise) {
                if (clearCurrentTask) {
                    activeTaskRef.value = undefined;
                }

                const response = await Api.get(`get-user-task/${taskId}`);
                if (response.data) {
                    setActiveTask(FullTaskSchema.parse(response.data));
                } else {
                    this.closeTask();
                }

                setErrors([]);
                taskPromise = undefined;
            }

            return taskPromise;
        },

        async refreshActiveTaskProgress() {
            const task = activeTaskRef.value;

            if (!task) {
                throw new Error('Cannot get active task progress as there is no active task!');
            }

            if (task.isComplete) {
                this.setSubmitTaskPrompt(false);
            } else {
                Api.get(`check-task-progress/${task.id}`).then((response) => {
                    this.setSubmitTaskPrompt(TaskProgressSchema.parse(response.data).promptSubmit);
                });
            }
        },

        closeTask() {
            activeTaskRef.value = undefined;
        },

        autosaveTask(data: FormData) {
            clearTimeout(autosaveTimeout);

            autosaveTimeout = setTimeout(() => {
                if (activeTaskRef.value) {
                    data.set('taskId', activeTaskRef.value.id.toString());
                    data.set('reviewId', activeTaskRef.value.peerReviewId.toString());

                    Api.post('autosave-task-form', data, activeTaskRef.value?._nonce);
                }
            }, 250);
        },

        async submitTask(formData = new FormData()) {
            const activeTask = activeTaskRef.value;

            if (activeTask) {
                setErrors([]);

                formData.set('taskId', activeTask.id.toString());

                const response = await Api.post('submit-task-form', formData, activeTask._nonce);
                const taskResponseData = TaskResponseSchema.parse(response.data);
                setErrors(taskResponseData.errors);

                if (taskResponseData.task) {
                    setActiveTask(FullTaskSchema.parse(taskResponseData.task));
                }

                if (taskResponseData.reviewData) {
                    useReviewData().updateReviewData(taskResponseData.reviewData);
                }

                if (taskResponseData.success) {
                    this.setSubmitTaskPrompt(false);
                    await useTasks().refresh();
                } else {
                    setTimeout(() => {
                        document.getElementById('task-errors')?.scrollIntoView();
                    }, 10);
                }

                return taskResponseData.success;
            }

            throw new Error('Cannot submit task because there is no active task!');
        }
    }
}